import { useUserStore } from '@@/stores/User.js';

export const useUser = () => {
  const userStore = useUserStore();

  const canShowAuthContent = computed(() => userStore.canShowAuthContent);
  const isAllAccess = computed(() => userStore.isAllAccess);
  const isFree = computed(() => userStore.isFree);
  const isGuest = computed(() => userStore.isGuest);
  const theme = computed(() => userStore.computedTheme);
  const units = computed(() => userStore.preferences.units);
  const isDark = computed(() => theme.value === 'dark');

  return {
    canShowAuthContent,
    isAllAccess,
    isDark,
    isFree,
    isGuest,
    theme,
    units,
  };
};
